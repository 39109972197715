import store from '@/store'
const OperationLog = require('@/assets/images/OperationLog.png')
const messageCenter = require('@/assets/images/messageCenter.png')
// const AccountCenter = require('@/assets/images/AccountCenter.png')
const QualificationReview = require('@/assets/images/QualificationReview.png')
const CreativeReview = require('@/assets/images/CreativeReview.png')
const FundManagement = require('@/assets/images/FundManagement.png')
const ControlInvoices = require('@/assets/images/ControlInvoices.png')
const ExpenseManagement = require('@/assets/images/ExpenseManagement.png')
const adxExpenseManagement = require('@/assets/images/adxExpenseManagement.png')
const RevenueStatistics = require('@/assets/images/RevenueStatistics.png')
const RevenueReview = require('@/assets/images/RevenueReview.png')
const agreement = require('@/assets/images/agreement.png')
const yuangongguanli = require('@/assets/images/yuangongguanli.png')
const RolePermissions = require('@/assets/images/RolePermissions.png')
const zhanghuguanli = require('@/assets/images/zhanghuguanli.png')
const APPsdk = require('@/assets/images/APPsdk.png')
const kefu = require('@/assets/images/kefu.png')
const sckz = require('@/assets/images/sckz.png')
const shield = require('@/assets/images/shield.png')

const title = store.getters.title

export const menuList = [
  {
    dataIndex: 'name',
    title: '账户中心',
    checked: false,
    children: [
      // {
      //   name: '账户信息',
      //   routeName: 'account',
      //   id: 1,
      //   icon: AccountCenter,
      //   router: 'account',
      //   checked: false,
      //   desc: '登录用户的基本信息'
      // },
      {
        name: '操作日志',
        routeName: 'operationLog',
        id: 9,
        router: 'operationLog',
        icon: OperationLog,
        checked: false,
        desc: '在此可通过日志溯源操作行为'
      },
      {
        name: '消息中心',
        routeName: 'message',
        id: 14,
        router: 'message',
        icon: messageCenter,
        checked: false,
        desc: '系统信息通知中心'
      },
      {
        name: '工单管理',
        routeName: 'workOrder',
        id: 15,
        icon: kefu,
        checked: false,
        router: 'workOrder',
        desc: '工单管理'
      },
      {
        name: '新建工单',
        routeName: 'addWorkOrder',
        id: 16,
        checked: false,
        hidden: true
      }
    ]
  },
  {
    dataIndex: 'action',
    title: '账户权限',
    checked: false,
    children: [
      {
        name: '角色权限',
        routeName: 'rights',
        id: 12,
        router: 'rights',
        icon: RolePermissions,
        checked: false,
        desc: '不同角色账户拥有不同功能操作与数据查看权限'
      },
      {
        name: '用户管理',
        routeName: 'personnel',
        id: 10,
        router: 'personnel',
        icon: yuangongguanli,
        checked: false,
        desc: '依据不同角色创建的不同权限的用户'
      },
      {
        name: '账户管理',
        routeName: 'accountManage',
        id: 11,
        router: 'accountManage',
        icon: zhanghuguanli,
        checked: false,
        desc: '管理管理员及媒体角色'
      },
      {
        name: 'SDK版本管理',
        routeName: 'sdkManage',
        id: 13,
        router: 'sdkManage',
        icon: APPsdk,
        checked: false,
        desc: `管理${title} SDK版本及联盟广告版本`
      },
      {
        name: '新增账户',
        routeName: 'addAccountManage',
        id: 14,
        checked: false,
        hidden: true
      },
      {
        name: '角色权限',
        routeName: 'addingrolerights',
        id: 15,
        checked: false,
        hidden: true
      }
    ]
  },
  {
    dataIndex: 'platPlaceId',
    title: '财务管理',
    checked: false,
    children: [
      {
        name: '资金管理',
        routeName: 'money',
        id: 2,
        icon: FundManagement,
        router: 'money',
        checked: false,
        desc: '账户充值及消耗数据'
      },
      {
        name: '收入统计',
        routeName: 'income',
        id: 3,
        icon: RevenueStatistics,
        router: 'income',
        checked: false,
        desc: '一站式管理账户下各业务的收入'
      },
      {
        name: '收入审核',
        routeName: 'incomeAudit',
        id: 3,
        icon: RevenueReview,
        router: 'incomeAudit',
        checked: false,
        desc: '一站式审核账户下各业务的收入'
      },
      {
        name: '发票管理',
        routeName: 'invoice',
        id: 4,
        router: 'invoice',
        icon: ControlInvoices,
        checked: false,
        desc: '管理与合作伙伴的合同信息'
      },
      {
        name: '合同管理',
        routeName: 'contract',
        id: 5,
        icon: agreement,
        checked: false,
        router: 'contract',
        desc: '管理与合作伙伴的合同信息'
      },
      {
        name: '支出管理',
        routeName: 'settlementManager',
        id: 6,
        icon: ExpenseManagement,
        checked: false,
        router: 'settlementManager',
        desc: '一站式管理账户下各业务的支出'
      },
      {
        name: '结算管理',
        routeName: 'ADXSettlementManager',
        id: 6,
        icon: adxExpenseManagement,
        checked: false,
        router: 'ADXSettlementManager',
        desc: '管理结算单'
      }
    ]
  },
  {
    dataIndex: 'position',
    title: '审核管理',
    checked: false,
    children: [
      {
        name: '资质审核',
        routeName: 'audit',
        id: 7,
        icon: QualificationReview,
        checked: false,
        router: 'audit',
        desc: '审核各角色账户资质信息'
      },
      {
        name: '创意审核',
        routeName: 'originality',
        id: 8,
        icon: CreativeReview,
        checked: false,
        router: 'originality',
        desc: '广告素材审核中心'
      },
      {
        name: '素材快照',
        routeName: 'sckz',
        id: 9,
        icon: sckz,
        checked: false,
        router: 'sckz',
        desc: '查看流量投放素材和广告情况'
      },
      {
        name: '屏蔽管理',
        routeName: 'creativemask',
        id: 10,
        icon: shield,
        checked: false,
        router: 'creativemask',
        desc: '广告白名单设置'
      }
    ]
  }
]
